import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Layout from '../components/Layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(4, 0)
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    }
  ),
);

const FAQs: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout contentClassName={classes.root}>
        <Typography variant="h5" paragraph align="center">FAQs</Typography>
      <Container maxWidth="md">

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              What is Lease Mojo?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Lease Mojo makes choosing and leasing your perfect car easy and enjoyable. By comparing the best offers from our dealers, you get the car you want, from the dealer you like best, at a price that’s right for you.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Why is it so cheap?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              When dealers send you the lease offers, they see all other offers that you have and will compete with each other to give you the best deal. It's like a reverse auction for our lovely customers.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              How do I start?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Simply choose your car with our easy to use car picker and hit "Submit", and shortly after that nearby dealers will start sending you offers?
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              What is car leasing?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              A car lease lets you drive a new vehicle without paying a large sum of cash or taking out a loan. To lease a car, you simply make a small down payment — followed by monthly payments for the term of the lease.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Is this better than going directly to the dealership?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              On average our customers get 20% lower monthly payments. With Leasemojo.com you directly get offers from multiple dealerships which guarantees that the price will be much better.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Do I commit to anything?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              No, with Leasemojo you do not commit to anything, we simply make it super easy to get anonymous quotes from multiple dealers at the same time, without wasting your valuable time.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Is my contact information secure?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Yes, We will never share your contact information with dealers. You can communicate with a dealer with our super fast and secure chat unless you decide to call the dealer directly.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Does the offer that I receive expire?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Dealers send you offers based on real cars (that are available) in their inventory. Sometimes by the time you contact them to make a deal, that car might be sold already. In that case, they will make you another offer. So act fast, if you receive the offer that you like.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </Container>
    </Layout>
  );
};

export default FAQs;